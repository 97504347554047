const searchTypeStart = document.getElementById('front_search_type_start');
const placeholderInput = searchTypeStart ? searchTypeStart.getAttribute('placeholder') : undefined;
const ariaDescInput = searchTypeStart ? searchTypeStart.getAttribute('aria-description') : undefined;

if (searchTypeStart) {
  searchTypeStart.addEventListener('focus', () => {
    searchTypeStart.placeholder = ariaDescInput;
  });

  searchTypeStart.addEventListener('blur', () => {
    searchTypeStart.placeholder = placeholderInput;
  });
}
